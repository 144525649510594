import { Box, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useIsSuperAdmin } from './useIsSuperAdmin';

const RedBox = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));

export const SuperAdminBox = ({ children, sx }: { children: ReactNode; sx?: SxProps<Theme> }) => {
  const isSuperAdmin = useIsSuperAdmin();
  if (!isSuperAdmin) {
    return null;
  }
  return <RedBox sx={sx}>{children}</RedBox>;
};
