import { TextField } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useActualMembershipRole, useForcedMembershipRoleAtom } from '@/components/hooks/MembershipRole';
import { OverflowAutocomplete } from './OrgSelector';
import { MembershipRole } from '@/web-types';

interface MemberRoleOption {
  label: string;
  id: MembershipRole;
}

export const MemberRoleSelector = () => {
  const [forcedRole, setForcedRole] = useForcedMembershipRoleAtom();
  const actualMembershipRole = useActualMembershipRole();

  const allOptions = useMemo(
    () =>
      [MembershipRole.Owner, MembershipRole.Admin, MembershipRole.User, MembershipRole.Feeder].map((id) => ({
        label: id === actualMembershipRole ? `${id} (actual)` : id,
        id,
      })),
    [actualMembershipRole]
  );

  const onChange = useCallback(
    (e: unknown, newValue: unknown) => {
      setForcedRole((newValue as MemberRoleOption).id);
    },
    [setForcedRole]
  );

  const isOptionEqualToValue = useCallback((option: unknown, value: unknown) => {
    return (option as MemberRoleOption).id === value;
  }, []);

  return (
    <OverflowAutocomplete
      options={allOptions}
      renderInput={(params) => <TextField {...params} />}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable
      blurOnSelect
      clearOnBlur
      selectOnFocus
      componentsProps={{ popper: { style: { width: '700px' } } }}
      forcePopupIcon={false}
      value={forcedRole ?? actualMembershipRole ?? null}
      onChange={onChange}
      size="small"
    />
  );
};
