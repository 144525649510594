import { atom, useAtom } from 'jotai';
import { useOrgId } from '@/components/Providers/auth/OrgIdProvider';
import { useUserPermissionsAtom } from './Permissions';
import { MembershipRole } from '@/web-types';

export const forcedMembershipRoleAtom = atom<MembershipRole | null>(null);

export const useForcedMembershipRoleAtom = () => useAtom(forcedMembershipRoleAtom);

export const useActualMembershipRole = () => {
  const { orgId } = useOrgId();
  const [permissions] = useUserPermissionsAtom();
  return (permissions?.memberships ?? []).find((m) => m.orgId === orgId)?.role as MembershipRole | null;
};

export const useMembershipRole = () => {
  const [forcedRole] = useForcedMembershipRoleAtom();
  const actualRole = useActualMembershipRole();

  if (forcedRole) {
    return forcedRole;
  }
  return actualRole;
};
