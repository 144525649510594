import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NextLink from 'next/link';
import * as React from 'react';
import { styled } from '@mui/material/styles';

export type NavigationItemProps = {
  href: string;
  label: string;
  active: boolean;
  open: boolean;
  Icon: React.ElementType;
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
  marginBottom: theme.spacing(1),
}));

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'active',
})<{ open: boolean; active: boolean }>(({ theme, open, active }) => ({
  height: theme.spacing(7),
  borderRadius: theme.spacing(0.5),
  justifyContent: 'center',
  flexDirection: open ? 'row' : 'column',
  backgroundColor: active ? theme.palette.primary.main : 'inherit',
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[200],
  },
  transition: '.3s all',
}));

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  minWidth: 0,
  marginRight: open ? theme.spacing(3) : 'auto',
  marginLeft: open ? 0 : 'auto',
  marginBottom: open ? 0 : 2,
}));

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'active',
})<{ open: boolean; active: boolean }>(({ theme, open, active }) => ({
  color: active ? 'white' : theme.palette.text.secondary,
  '& .MuiListItemText-primary': {
    fontWeight: open ? 600 : 400,
    fontSize: open ? '1rem' : '0.8em',
    fontFamily: '"Helvetica Neue"; Helvetica; Arial; sans-serif',
  },
  margin: 0,
}));

const NavigationItem = ({ href, label, active, open, Icon }: NavigationItemProps) => (
  <Link component={NextLink} href={href} underline="none">
    <StyledListItem disablePadding>
      <StyledListItemButton open={open} active={active}>
        <StyledListItemIcon open={open}>
          <Icon sx={{ color: active ? 'white' : 'inherit' }} />
        </StyledListItemIcon>
        <StyledListItemText primary={label} open={open} active={active} />
      </StyledListItemButton>
    </StyledListItem>
  </Link>
);

export default NavigationItem;
