import { SuperAdminBox } from '@/components/Admin/SuperAdminBox';
import { useIsSuperAdmin } from '@/components/Admin/useIsSuperAdmin';
import { UpdateAlert } from '@/components/UpdateNotification';
import { useMembershipRole } from '@/components/hooks/MembershipRole';
import { useUserPermissionsAtom } from '@/components/hooks/Permissions';
import { useDesktopModeAtom, useIsPhoneSize } from '@/components/hooks/useResponsive';
import { MembershipRole } from '@/web-types';
import { AccountBalance, BarChartOutlined } from '@mui/icons-material';
import FenceIcon from '@mui/icons-material/Fence';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MenuIcon from '@mui/icons-material/Menu';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Stack from '@mui/material/Stack';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { showGenericModal } from '../GenericModal/GenericModal';
import Drawer from './Drawer';
import { MemberRoleSelector } from './MemberRoleSelector';
import NavigationItem from './NavigationItem';
import { OrgSelector } from './OrgSelector';
import { NavigationContext } from './context';
import { useIsProdWarning } from './hooks';

interface MobileNavItem {
  label: string;
  href: string | null;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
}

const NavPens = { label: 'Pens', href: '/dashboard/pens', Icon: FenceIcon };
const NavLots = { label: 'Lots', href: '/dashboard/lots', Icon: LocalOfferOutlinedIcon };
const NavInventory = {
  label: 'Inventory',
  href: '/dashboard/inventory',
  Icon: WarehouseOutlinedIcon,
};
const NavMedicalInventory = {
  label: 'Health',
  href: '/dashboard/medical-inventory',
  Icon: MedicalServicesIcon,
};
const NavFeeding = { label: 'Feeding', href: '/dashboard/feeding', Icon: RestaurantIcon };
const NavBilling = { label: 'Billing', href: '/dashboard/billing', Icon: AccountBalance };
const NavReports = { label: 'Reports', href: '/dashboard/reports', Icon: BarChartOutlined };
const NavDesktopMode = { label: 'Desktop', href: null, Icon: FullscreenIcon };
const NavSwitchYard = { label: 'Switch Yard', href: null, Icon: SyncAltIcon };

const FOOTER_NAV_ITEMS = [{ label: 'Settings', href: '/dashboard/settings', Icon: SettingsIcon }];

const DashboardLayoutMenuContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'displayProdWarning',
})<{ displayProdWarning?: boolean }>(({ theme, displayProdWarning = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: displayProdWarning ? theme.palette.yellow[300] : theme.palette.grey[50],
  height: '100vh',
  color: theme.palette.grey[600],
  padding: `${theme.spacing(1)} 0`,
}));

const MobileNavContainer = styled(Box)(() => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
}));

const StyledBottomNavigation = styled(BottomNavigation, {
  shouldForwardProp: (prop) => prop !== 'displayProdWarning',
})<{ displayProdWarning?: boolean }>(({ theme, displayProdWarning = false }) => ({
  backgroundColor: displayProdWarning ? theme.palette.yellow[300] : theme.palette.grey[50],
  borderRadius: `${theme.spacing(3)} ${theme.spacing(3)} 0 0`,
  boxShadow: '0px -4px 6px -1px rgba(16, 24, 40, 0.10)',
  height: 'auto',
  padding: `${theme.spacing(1)} ${theme.spacing(2)} calc(${theme.spacing(1)} + env(safe-area-inset-bottom))`,
  '.MuiButtonBase-root': {
    minWidth: 0,
  },
  '.MuiBottomNavigationAction-label, .Mui-selected': {
    fontSize: '12px',
  },
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} env(safe-area-inset-bottom)`,
  },
}));

export const Main = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'usePadding' && prop !== 'useHeightAuto',
})<{ usePadding?: boolean; useHeightAuto?: boolean }>(({ usePadding = true, useHeightAuto = false, theme }) => ({
  ...(usePadding
    ? {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        [theme.breakpoints.down('md')]: {
          paddingTop: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingBottom: `calc(env(safe-area-inset-bottom) + ${theme.spacing(8)})`,
        },
      }
    : {}),
  width: '100%',
  height: useHeightAuto ? 'auto' : '100%',
  flexDirection: 'column',
  display: 'inline-flex',
}));
Main.defaultProps = {
  component: 'main',
};

const DashboardWrapper = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
  paddingTop: 'env(safe-area-inset-top)',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  minHeight: theme.spacing(6),
  minWidth: theme.spacing(6),
}));

const StyledMenuHead = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  padding: '0 14px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: open ? 'space-between' : 'center',
  marginBottom: theme.spacing(5),
}));

const SuperAdminBoxBox = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  marginBottom: theme.spacing(1),
}));

const LogoWrap = styled(Box)(({ theme }) => ({
  padding: '0 21px',
  marginBottom: theme.spacing(3),
}));

const ContentWrapper = styled(Box)(() => ({
  flexGrow: 1,
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  overflow: 'auto',
}));

export type DashboardLayoutProps = {
  usePadding?: boolean;
  useHeightAuto?: boolean;
  children?: React.ReactNode;
};

const MobileNav = () => {
  const isSuperAdmin = useIsSuperAdmin();
  const membershipRole = useMembershipRole();
  const [permissions] = useUserPermissionsAtom();
  const [, setDesktopMode] = useDesktopModeAtom();
  const router = useRouter();
  const displayProdWarning = useIsProdWarning();

  const MAIN_NAV_ITEMS_MOBILE = useMemo(() => {
    let items: MobileNavItem[] = [];
    if (membershipRole === MembershipRole.Feeder) {
      items = [NavFeeding];
    } else {
      items = [NavPens, NavLots, NavFeeding, NavInventory, NavMedicalInventory];
    }

    if (isSuperAdmin) {
      items.push(NavDesktopMode);
    } else if (permissions?.memberships?.length && permissions?.memberships?.length > 1) {
      items.push(NavSwitchYard);
    }

    return items;
  }, [isSuperAdmin, membershipRole, permissions?.memberships?.length]);

  const value = MAIN_NAV_ITEMS_MOBILE.findIndex(({ href }) => href && router.pathname.indexOf(href) === 0) ?? 0;

  const onNavItemClick = useCallback(
    (event: unknown, newValue: number) => {
      const { href, label } = MAIN_NAV_ITEMS_MOBILE[newValue];
      if (href) {
        router.push(href);
      } else if (label === NavDesktopMode.label) {
        setDesktopMode(true);
      } else if (label === NavSwitchYard.label) {
        showGenericModal({
          content: (
            <Box sx={{ position: 'relative' }}>
              <Typography variant="h2" sx={{ pb: 1 }}>
                Switch Yard
              </Typography>

              <OrgSelector componentsProps={{}} />
            </Box>
          ),
        });
      }
    },
    [MAIN_NAV_ITEMS_MOBILE, router, setDesktopMode]
  );

  return (
    <MobileNavContainer>
      <StyledBottomNavigation
        displayProdWarning={displayProdWarning}
        showLabels
        value={value}
        onChange={onNavItemClick}
      >
        {MAIN_NAV_ITEMS_MOBILE.map(({ label, Icon }) => (
          <BottomNavigationAction
            key={label}
            label={<Typography whiteSpace="nowrap">{label}</Typography>}
            icon={<Icon />}
          />
        ))}
      </StyledBottomNavigation>
    </MobileNavContainer>
  );
};

export function DashboardLayoutBase({ children }: Readonly<DashboardLayoutProps>) {
  const { open, setOpen } = useContext(NavigationContext);
  const router = useRouter();
  const isPhoneSize = useIsPhoneSize();
  const membershipRole = useMembershipRole();
  const displayProdWarning = useIsProdWarning();

  const [, setDesktopMode] = useDesktopModeAtom();
  const handleDrawerToggle = useCallback(() => setOpen((state) => !state), [setOpen]);

  const MAIN_NAV_ITEMS = useMemo(() => {
    switch (membershipRole) {
      case MembershipRole.Feeder:
        return [NavFeeding];
      case MembershipRole.User:
        return [NavPens, NavLots, NavInventory, NavMedicalInventory, NavFeeding];
      default:
        return [NavPens, NavLots, NavInventory, NavMedicalInventory, NavFeeding, NavBilling, NavReports];
    }
  }, [membershipRole]);

  useEffect(() => {
    const onRouteChangeComplete = () => {
      setOpen(false);
    };
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events, setOpen]);

  return (
    <DashboardWrapper>
      {!isPhoneSize && (
        <Drawer variant="permanent" open={open}>
          <DashboardLayoutMenuContainer displayProdWarning={displayProdWarning}>
            <List>
              <StyledMenuHead open={open}>
                {open && <Image src="/assets/images/logo-145x37.svg" alt="Logo" width={145} height={37} />}
                <StyledIconButton onClick={handleDrawerToggle}>
                  <MenuIcon />
                </StyledIconButton>
              </StyledMenuHead>
              <Box mb={4}>
                {MAIN_NAV_ITEMS.map(({ label, href, Icon }) => (
                  <NavigationItem
                    key={label}
                    open={open}
                    active={router.pathname.indexOf(href) === 0}
                    label={label}
                    href={href}
                    Icon={Icon}
                  />
                ))}
              </Box>

              <SuperAdminBox>
                <SuperAdminBoxBox>
                  <Link href="/dashboard/admin">Admin</Link>
                </SuperAdminBoxBox>
                <SuperAdminBoxBox>
                  <Link href="" onClick={() => setDesktopMode(false)}>
                    Mobile
                  </Link>
                </SuperAdminBoxBox>
                <Stack spacing={1} sx={{ marginTop: 1, p: 1 }}>
                  <OrgSelector />
                  <MemberRoleSelector />
                </Stack>
              </SuperAdminBox>
            </List>
            <List>
              {!open && (
                <LogoWrap>
                  <Image src="/assets/images/logo-38x38.svg" alt="Logo" width={38} height={38} priority />
                </LogoWrap>
              )}
              {FOOTER_NAV_ITEMS.map(({ label, href, Icon }) => (
                <NavigationItem
                  key={label}
                  open={open}
                  active={router.pathname.indexOf(href) === 0}
                  label={label}
                  href={href}
                  Icon={Icon}
                />
              ))}
            </List>
          </DashboardLayoutMenuContainer>
        </Drawer>
      )}
      <ContentWrapper>
        <UpdateAlert />
        {children}
      </ContentWrapper>
      {isPhoneSize && <MobileNav />}
    </DashboardWrapper>
  );
}

export default function DashboardLayout({
  usePadding = true,
  useHeightAuto = false,
  children,
}: Readonly<DashboardLayoutProps>) {
  return (
    <DashboardLayoutBase>
      <Main usePadding={usePadding} useHeightAuto={useHeightAuto}>
        {children}
      </Main>
    </DashboardLayoutBase>
  );
}
