import { useOrgId } from '@/components/Providers/auth/OrgIdProvider';
import { useIsSuperAdmin } from '../Admin/useIsSuperAdmin';

const testOrgSet = new Set([
  10, // Jack
  11, // Aurelien
  15, // Demo's
  17, // Ray
  19, // iOS contractor
  25, // Peter
  108, // Warren
  110, // Charles
  112, // Tomas
]);

export const useIsProdWarning = () => {
  const isSuperAdmin = useIsSuperAdmin();
  const { orgId } = useOrgId();
  const isTestOrg = orgId && testOrgSet.has(orgId);
  return !isTestOrg && isSuperAdmin && process.env.NODE_ENV === 'production';
};
