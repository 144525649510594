import { DialogActions, DialogContainer, DialogContent } from '@/components/Dialog';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { ModalProps } from '@mui/material/Modal';
import { ReactNode } from 'react';

interface GenericModalProps {
  content: ReactNode;
  actions?: ReactNode;
  cancelButton?: string;
  onCancel?: () => void;
  DialogProps?: Omit<DialogProps, 'open'>;
  DialogContainerProps?: BoxProps;
  DialogContentProps?: BoxProps;
  DialogActionsProps?: BoxProps;
}

export const showGenericModal = (props: GenericModalProps) => ModalManager.show(GenericModal, props);

const GenericModal = ModalManager.create<GenericModalProps>(
  ({
    content,
    actions = null,
    cancelButton = 'Cancel',
    onCancel,
    DialogProps = {},
    DialogContainerProps = {},
    DialogContentProps = {},
    DialogActionsProps = {},
  }) => {
    const modal = useModal();

    // This is to prevent the modal from closing when clicking on the backdrop
    const onClose: ModalProps['onClose'] = (event, reason) => {
      if (reason && reason === 'backdropClick') return;
      modal.remove();
    };

    return (
      <Dialog onClose={onClose} maxWidth="xs" fullWidth open={modal.visible} {...DialogProps}>
        <DialogContainer {...DialogContainerProps}>
          <DialogContent sx={{ py: 2 }} {...DialogContentProps}>
            {content}
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }} {...DialogActionsProps}>
            <Button
              data-testid="confirmation-modal-cancel-button"
              onClick={() => {
                onCancel?.();
                modal.remove();
              }}
              variant="text"
            >
              {cancelButton}
            </Button>
            {actions}
          </DialogActions>
        </DialogContainer>
      </Dialog>
    );
  }
);
